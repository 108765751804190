<template>
	<ValidationObserver ref="form">
		<p class="m-auth-descript text-custom small">Por favor inicia sesión con tu cuenta</p>
		<form id="login" method="POST" @submit.prevent="doLogin">
			<ValidationProvider rules="required|email" v-slot="{ errors }">
				<b-form-group
					label="Correo"
					label-for="email"
					label-align="left"
					class="text-custom"
					:class="{ 'border-error': errors[0] || message }"
				>
					<b-form-input
						class="m-form-input"
						id="email"
						type="text"
						v-model.trim="user.email"
						placeholder="user@smartroosterbureau.us"
					/>
					<Transition name="fade" mode="out-in">
						<span v-if="errors[0]" class="mt-50 d-block txt-error">
							{{ errors[0] }}
						</span>
					</Transition>
				</b-form-group>
			</ValidationProvider>
			<ValidationProvider rules="required" v-slot="{ errors }">
				<b-form-group
					label="Contraseña"
					label-for="password"
					label-align="left"
					class="text-custom"
					:class="{ 'border-error': errors[0] || message }"
				>
					<b-input-group>
						<template #append>
							<span
								class="d-flex justify-content-center align-items-center px-1 text-custom eye-content"
								@click="toggleInputTypeHandle"
							>
								<b-icon-eye v-if="toggleInputType == 'password'" />
								<b-icon-eye-slash v-else />
							</span>
						</template>
						<b-form-input
							class="m-form-input"
							id="password"
							:type="toggleInputType"
							v-model="user.password"
							placeholder="············"
						/>
					</b-input-group>
					<Transition name="fade" mode="out-in">
						<span v-if="errors[0]" class="mt-50 d-block txt-error">
							{{ errors[0] }}
						</span>
					</Transition>
				</b-form-group>
			</ValidationProvider>

			<div class="d-flex justify-content-between align-items-center">
				<div class="d-flex py-2">
					<input type="checkbox" id="remeber-me" hidden class="box-check" v-model="rememberMe" />
					<label for="remeber-me" class="d-flex align-items-center box-check-target">
						<span class="box-dot"></span>
						<span class="text-custom ml-2 a-label">Recuérdame</span>
					</label>
				</div>

				<div class="d-flex py-2">
					<router-link :to="{ name: 'forget-password' }">¿ Olvidaste tu contraseña ?</router-link>
				</div>
			</div>
			<b-button block variant="primary" class="a-btn-submit" type="submit" tabindex="4">
				<b-spinner small v-if="loading" class="mr-50"></b-spinner>
				<span>{{ loading ? "Verificando..." : "Iniciar sesión" }}</span>
			</b-button>
			<Transition name="fade" mode="in-out">
				<div v-if="message" class="text-center text-warning mt-2">
					<h5 class="text-warning">{{ message }}</h5>
				</div>
			</Transition>
		</form>
	</ValidationObserver>
</template>

<script>
import { mapActions } from "vuex"

export default {
	name: "Login",
	mounted() {
		const rememberme = JSON.parse(localStorage.getItem("rememberme") || false)
		if (rememberme) {
			const { email, password } = rememberme
			this.user.email = email
			this.user.password = password
			this.rememberMe = true
		}
	},
	data() {
		return {
			rememberMe: false,
			user: {
				email: "",
				password: "",
			},
			toggleInputType: "password",
			loading: false,
			message: null,
		}
	},
	methods: {
		...mapActions("auth", ["login"]),
		toggleInputTypeHandle() {
			this.toggleInputType = this.toggleInputType === "password" ? "text" : "password"
		},
		async doLogin() {
			if (!(await this.$refs.form.validate())) return

			this.loading = true
			if (this.rememberMe) {
				localStorage.setItem("rememberme", JSON.stringify(this.user))
			} else {
				localStorage.removeItem("rememberme")
			}

			try {
				this.message = null
				const res = await this.login(this.user)
				console.log(res)
			} catch (error) {
				this.message = error.response?.data?.message || error.message
			} finally {
				this.loading = false
			}
		},
	},
}
</script>
